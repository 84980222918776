<template>
  <div class="home-wrapper--destinations">
    <el-container class="wrapper-destinations">
      <el-main>
        <div class="wrapper-section-header">
          <h2 class="home-section-title">{{ $t(`${base}.title`) }}</h2>
          <p class="home-section-description">{{
            $t(`${base}.description`)
          }}</p>
        </div>

        <div class="container-destination-links">
          <LadHomeDestinationContinent
            v-for="continent in continents"
            :key="continent.code"
            v-bind="continent"
            :base="base"
          />
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CacheComponentMixin from '@/utils/mixin/cache-components'

export default {
  name: 'LadHomeDestinations',
  components: {
    LadHomeDestinationContinent: () =>
      import(
        /* webpackChunkName: "lad-home-destination-continent" */ './continent'
      ),
  },
  mixins: [CacheComponentMixin],
  props: {
    base: { type: String, default: 'home.destinations' },
  },
  computed: {
    ...mapState('settings', ['continents']),
  },
}
</script>

<style lang="scss">
.home-wrapper--destinations {
  background-color: #ebeef2;
  padding: 0;

  .container-destination-links {
    display: grid;
    grid-gap: 15px;

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    .card-continent {
      position: relative;
      padding-bottom: 35px;
      margin: 0;

      .el-card__header {
        h3 {
          display: inline-block;
        }
      }

      .container-countries {
        display: grid;
        grid-gap: 5px;
        grid-template-columns: repeat(2, 1fr);

        .country-link {
          width: fit-content;
        }

        @media (min-width: 768px) {
          grid-template-columns: repeat(3, 1fr);
        }
      }

      .country-link {
        &.el-button {
          position: absolute;
          right: 20px;
          bottom: 10px;
          padding: 0;
        }
      }
    }
  }
}
</style>
